
<template>
	<div class="box">
		<div class='title' style=' padding-top:20px'>
			<el-form style="display:flex;flex-wrap:wrap" :model="searchlist"  label-width="150px" ref='searchlist'>
				  <!-- v-for循环截取循环的数组-->
					<el-form-item label-width="80px" v-for="(item) in searchType.slice(0,1)" :key='searchType.indexOf(item)' :label="item.title">
						<el-select  style="width:200px !important" clearable  :placeholder="'请输入'+item.title+'查询'" v-model='searchlist[item.formName]'>
							<el-option v-for="item in shequList" :label="item.name" :value="item.levelcode" :key='item.levelcode'></el-option>
						</el-select>
			    	</el-form-item>
					<el-form-item label-width="80px" v-for="(item) in searchType.slice(1,5)" :key='searchType.indexOf(item)' :label="item.title">
						<el-input style="width:200px !important" :placeholder="'请输入'+item.title+'查询'" v-model='searchlist[item.formName]'></el-input>
			    	</el-form-item>
					<el-form-item label-width="80px" v-for="(item) in searchType.slice(5,6)" :key='searchType.indexOf(item)' :label="item.title">
						<el-select clearable style="width:200px !important" :placeholder="'请输入'+item.title+'查询'" v-model='searchlist[item.formName]'>
							<el-option label="男" value="男"></el-option>
							<el-option label="女" value="女"></el-option>
						</el-select>
			    	</el-form-item>
					<el-form-item  v-for="(item) in searchType.slice(6,8)" :key='searchType.indexOf(item)' :label="item.title">
						<el-date-picker style="width:200px !important" :placeholder="'请输入'+item.title+'查询'" value-format='yyyy-MM-DD'  format='yyyy-MM-DD'  v-model='searchlist[item.formName]' type="date"></el-date-picker>
			    	</el-form-item>
               		<el-form-item label-width="10px">
						<div style="display:flex;align-items:center">
							<el-button type='primary' @click='search'>
			  	       			<i  class="el-icon-search" style="font-size: 14px;color: white;"></i>搜索       
			        		</el-button>
							<el-button type='primary' :disabled="tableData.length == 0" @click='exportXcle'>
            				    <i  class="el-icon-share" style="font-size: 14px;color: white;"></i>excle导出  
            				</el-button>
						</div>
                	</el-form-item>
            </el-form>
		</div>
		<div class="showdatanumber">共查询数据<span>{{ fenye.totalnum }}</span>条</div>  
		<div >
			<el-table
			  :data="tableData"
			  border
			  style="width: 100%;margin-top:20px"
			  stripe>
			 <el-table-column v-for='(item) in formtype'
			    :prop="item.formvalue"
			    :label="item.name"
				align="center"
				:key ="formtype.indexOf(item)">
			  </el-table-column>
			  <el-table-column
			    label="详情"
                width="300"
				align="center">
				<div slot-scope="scope" style="display:flex;flex-wrap: wrap;justify-content:center">
					<el-button type="primary" @click="change(scope.row)" >客户信息</el-button>
				</div>
				</el-table-column><!-- 不同状态对应操作-->
			</el-table>
            
            <el-table
               v-show='false'
              :data="tableData1"
              id='yishimingtable'>
             <el-table-column v-for='(item) in formtype'
                :prop="item.formvalue"
                :label="item.name"
            	align="center"
            	:key ="formtype.indexOf(item)">
              </el-table-column>
            </el-table>
		</div>
		

		<div class="dialog">
			<el-dialog
			:close-on-click-modal="false"
		  	:visible.sync="formDialog"
			v-if='formDialog'
			append-to-body
		  	width="60%">
				<span style="font-size: 20px;color:white" slot="title">客户信息</span>
                <AddForm :Mail='accountUuid'></AddForm>
			</el-dialog>
		</div>

		
		<div class='fenyekuang'>
			<el-pagination @size-change="sizeChange"
			@current-change="currentChange" :current-page="currentPage" 
			:page-sizes="fenye.pagesizes" :page-size="fenye.pagesize" 
			layout="total, sizes, prev, pager, next, jumper" :total="fenye.totalnum">
			</el-pagination>
		</div>
		<div class='shuiyin'>
		  <div>{{this.GLOBAL.phone()}}</div>
		  <div>{{this.GLOBAL.phone()}}</div>
		  <div>{{this.GLOBAL.phone()}}</div>
		  <div>{{this.GLOBAL.phone()}}</div>
		  <div>{{this.GLOBAL.phone()}}</div>
		  <div>{{this.GLOBAL.phone()}}</div>
		  <div>{{this.GLOBAL.phone()}}</div>
		  <div>{{this.GLOBAL.phone()}}</div>
		  <div>{{this.GLOBAL.phone()}}</div>
	  </div>
	</div>
</template>

<script>
	import FileSaver from 'file-saver'
	import XLSX from 'xlsx'
	import {yishenList} from '@/api/shiminglist/shiminglist.js'
	import {isShequ} from '@/api/shequ/shequ.js'
    import AddForm from '@/components/kehuDetail'
	export default {
        components:{
			AddForm,
        },
		mounted(){
			this.GLOBAL.shuquId().then(PromiseResult=>{
				this.searchlist.allshequ=PromiseResult
				this.getList()
			})
			isShequ({userid:this.GLOBAL.adminId()}).then(res=>{
			if(res.result==200){
				this.shequList=res.detail
			}else{
				this.$message.error(res.description)
			}
		})
		},
	    data() {	
	      return {
			shequList:[],//社区列表
			xiaoquList:[],//小区列表
	        tableData: [],//获取的表格数据
			tableData1: [],//导出的表格数据
			formDialog:false,//弹出框
			formtype:[
				{name:'序号',formvalue:'xuhao'},
				{name:'姓名',formvalue:'name'},
				{name:'性别',formvalue:'sex'},
				{name:'身份证号',formvalue:'personcard'},
				{name:'居住地址',formvalue:'address'},	
				{name:'联系电话',formvalue:'phone'},
			],//表单须填数据模板 
			searchlist:{
				allshequ:'',
				arealevel:'',
				personcard:'',
				address:'',
				name:'',
				phone:'',
				sex:'',
				birthdaybegin:'',
				birthdayend:'',
			},//搜索数据
			searchType:[
				{title:'社区',formName:'arealevel'},
				{title:'身份证号',formName:'personcard'},
				{title:'居住地址',formName:'address'},
				{title:'姓名',formName:'name'},	
				{title:'联系电话',formName:'phone'},
				{title:'性别',formName:'sex'},
				{title:'出生年月开始时间',formName:'birthdaybegin'},
				{title:'出生年月结束时间',formName:'birthdayend'},
			],//搜索框成员
			currentPage: 1,//当前页
			fenye: {
				pagesizes: [10, 20, 50],
				pagesize: 10,
				totalnum: 0
			},//分页数据
			accountUuid:'',//账户uuid
	      }
	    },
		methods: {
		  search(){
			this.currentPage=1
			this.getList()
		  },//搜索请求
		  sizeChange(val) {
		  	this.fenye.pagesize = val;
		  	this.currentPage = 1;
            this.getList()
		  },//展示条数改变时
		  currentChange(val) {
		  	this.currentPage = val;
            this.getList()
		  },//当前页改变时
		  getList(){
			  yishenList({
				  userid:this.GLOBAL.adminId(),
				  arealevel:this.searchlist.arealevel==''?this.searchlist.allshequ:this.searchlist.arealevel,
				  personcard:this.searchlist.personcard,
				  address:this.searchlist.address,
				  name:this.searchlist.name,
				  phone:this.searchlist.phone,
				  sex:this.searchlist.sex,
				  birthdaybegin:this.searchlist.birthdaybegin,
				  birthdayend:this.searchlist.birthdayend,
				  pageSize:this.fenye.pagesize,
				  pageNum:this.currentPage,
			  }).then(res=>{
				  if(res.result==200){
					  this.tableData=res.detail.list
					  this.fenye.totalnum=res.detail.totalRow
					  res.detail.list.map((item,index)=>{
						  item.xuhao=index+1
					  })  
				  }else{
					  this.$message.error(res.description);
				  }
			  })
		  },//获取表格数据和查询请求
		  closeDialog(){
			  this.formDialog=false
		  },//关闭表格
		  change(row){
			this.formDialog=true
			this.accountUuid=row.accountuuid
		  },//详情框
		  exportXcle(){
			 yishenList({
				  userid:this.GLOBAL.adminId(),
				  arealevel:this.searchlist.arealevel==''?this.searchlist.allshequ:this.searchlist.arealevel,
				  personcard:this.searchlist.personcard,
				  address:this.searchlist.address,
				  name:this.searchlist.name,
				  phone:this.searchlist.phone,
				  sex:this.searchlist.sex,
				  birthdaybegin:this.searchlist.birthdaybegin,
				  birthdayend:this.searchlist.birthdayend,
				  pageSize:999999,
				  pageNum:this.currentPage,
			  }).then(res=>{
				  if(res.result==200){
					  this.tableData1 = res.detail.list;
						res.detail.list.map((item,index)=>{
						  item.xuhao=index+1
					  })
						let downloadTimer = setTimeout(()=>{
            clearTimeout(downloadTimer);
							let xlsxParam = { raw: true };
							var wb = XLSX.utils.table_to_book(document.querySelector("#yishimingtable"),xlsxParam);
							var wbout = XLSX.write(wb, {
								bookType: "xlsx",
								bookSST: true,
								type: "array",
							});
							try {
								FileSaver.saveAs(
									new Blob([wbout], { type: "application/octet-stream" }),"已实名人员.xlsx"
								);
							} catch (e) {
								if (typeof console !== "undefined") console.log(e, wbout);
							}
							return wbout;
						},10)
				  }else{
					  this.$message.error(res.description);
				  }
			  })
			}	
		},
	  }
</script>
<style lang='scss'>
	.showdatanumber{
      margin: 0 10px 10px;
      color: #409EFF;
      span{
        margin: 0 5px;
        font-size: 20px;
      }
    }
	.box{
		margin:10px;
		position: relative;
		z-index: 1;
		.el-table th , .el-table tr{
			background-color: transparent;
		}
		.el-table{
			background-color: transparent;	
		}
		
		.shuiyin{
			z-index: -1;
			position:absolute;
			width: 100%;
			height: 100%;
			top: 20%;
			left: 0%;
			font-size: 40px;
			opacity: 0.1;
			display:grid;
			grid-template-columns:repeat(3,33.33%);
    	    grid-template-rows:repeat(3,33.33%);
			div{
				transform: translate(-50%,-50%);
				transform: rotate(-20deg);
			}
		}
	}
</style>
<style scoped>
	.playBtn{
		padding:5px !important;
		margin-left: 4px !important;
	}
	.title{
		width: 100%;
		display: flex;
        align-items: center;
	}
	.fenyekuang{
		  width: 100%;
		  height:50px;
		  margin-top: 1px;
		  display: flex;
		  justify-content: flex-end;
		  align-items: center;
	}
</style>


