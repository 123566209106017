import request from '@/utils/request'


// 待实名审核列表
export function daishenList(params) {
  return request({
    url: '/MA_UserInfo/FindUserInfoPage',
    method: 'get',
    params:params
  })
}

//已实名列表
export function yishenList(params) {
    return request({
      url: '/MA_UserInfo/FindUserShiMingPage',
      method: 'get',
      params:params
    })
  }

//实名审核
export function shenHe(params) {
  return request({
    url: '/MA_UserInfo/FindUserInfoCheck',
    method: 'get',
    params:params
  })
}

//实名信息补全
export function mailBuQuan(params) {
  return request({
    url: '/MA_UserInfo/UserInfoUpdate',
    method: 'get',
    params:params
  })
}

//获取社区下小区列表
export function xiaoquList(params) {
  return request({
    url: '/region/FindByXiaoQu',
    method: 'get',
    params:params
  })
}
